<template>
  <v-app>
    <v-main style="width: 100%; height: 100%;">
      <div id="player"></div>
    </v-main>
  </v-app>
</template>

<script>
import aws4 from 'aws4';
import firebase from 'firebase/app';
import 'firebase/firestore';

// readonly access
var accessKey = 'OAGBR68DL9PXDB9F3UKS';
var secretKey = 'e9BEhMD78DSP7cOQqsgwBHIBdHMEWiXDz0cMhUP0';

export default {
  metaInfo () {
    return {
      title: this.tv.name + ' S' + this.season_number.padStart(2, '0') + 'E' + this.episode_number.padStart(2, '0')
    };
  },
  data () {
    return {
      userId: firebase.auth().currentUser.uid,
      updating: false,
      startTime: 0,
      tv: null,
      season: null,
      season_number: null,
      episode: null,
      episode_number: null,
      nextEpisode: null,
      watchRef: null,
      playRef: null,
      watchingTime: 0
    };
  },
  methods: {
    initialize: function () {
      let tvId = this.$route.params.id;
      let seasonNumber = this.$route.params.season;
      this.season_number = seasonNumber;
      let episodeNumber = this.$route.params.episode;
      this.episode_number = episodeNumber;

      let tvRef = firebase.firestore().collection('tv').doc(tvId + '');
      tvRef.get().then(tv => {
        this.tv = tv.data();
      });
      this.tv = { id: tvId };
      tvRef.collection('seasons').where('season_number', '==', parseInt(seasonNumber)).get().then(seasons => {
        seasons.forEach(season => {
          this.season = season.data();
          season.ref.collection('episodes').where('episode_number', '>=', parseInt(episodeNumber)).orderBy('episode_number', 'asc').limit(2).get().then(episodes => {
            episodes.forEach(episode => {
              if (episode.data().episode_number === parseInt(episodeNumber)) {
                this.episode = episode.data();

                this.watchRef = firebase.firestore().collection('users').doc(this.userId).collection('tv_watches').doc(this.tv.id + '').collection('seasons').doc(seasonNumber).collection('episodes').doc(episodeNumber);
                this.watchRef.get().then(watch => {
                  if (watch.data()) {
                    this.startTime = watch.data().time;
                  } else {
                    this.watchRef.set({
                      episode_number: parseInt(episodeNumber),
                      created: new Date(),
                      modified: new Date(),
                      time: 0
                    })
                  }
                }).finally(() => {
                  this.initializePlayer();
                });
              } else {
                this.nextEpisode = episode.data();
              }
            });
          });
        });
      });

      // Watching stats
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('tv_watches').doc(tvId).get().then(query => {
        if (query.exists) {
          query.ref.update({
            id: tvId,
            modified: new Date()
          });
        } else {
          query.ref.set({
            id: tvId,
            created: new Date(),
            modified: new Date()
          });
        }
        query.ref.collection('seasons').doc(seasonNumber).get().then(season => {
          if (season.exists) {
            season.ref.update({
              season_number: parseInt(seasonNumber),
              modified: new Date()
            });
          } else {
            season.ref.set({
              season_number: parseInt(seasonNumber),
              created: new Date(),
              modified: new Date()
            });
          }
        });
      });
    },
    initializePlayer: function () {
      // eslint-disable-next-line no-undef
      let playerInstance = jwplayer('player');

      this.watchingTime = 0;

      playerInstance.setup({
        width: 'auto',
        height: '100%',
        autostart: 'viewable',
        stretching: 'uniform',
        cast: {
          customAppId: '03388A69'
        },
        'playlist': this.playlist()
      });

      playerInstance.on('ready', (e) => {
        this.playRef = firebase.firestore().collection('plays').doc();
        this.playRef.set({
          type: 'tv',
          id: this.tv.id + '',
          timestamp: new Date(),
          season_number: this.season.season_number,
          episode_number: this.episode.episode_number,
          time: 0,
          duration: null,
          watchingTime: 0,
          user: {
            uid: firebase.auth().currentUser.uid,
            displayName: firebase.auth().currentUser.displayName,
            email: firebase.auth().currentUser.email
          }
        });
      });

      playerInstance.on('time', (e) => {
        if (!this.updating && e.position > 5) {
          this.updating = true;
          this.watchingTime += 5;
          this.playRef.update({
            time: Math.floor(e.position),
            duration: Math.floor(e.duration),
            watchingTime: this.watchingTime
          });
          let watched = false;
          // Consider a movie watched if less than 6 minutes are remaining
          if (Math.floor(e.duration) - Math.floor(e.position) <= 360) {
            watched = true;
          }
          this.watchRef.update({
            modified: new Date(),
            time: Math.floor(e.position),
            duration: Math.floor(e.duration),
            episode_number: parseInt(this.episode.episode_number),
            watched: watched
          }).finally(() => {
            setTimeout(() => {
              this.updating = false;
            }, 5000);
          });
        }
      });

      playerInstance.on('playlistItem', (e) => {
        if (this.watchingTime > 10) {
          if (this.nextEpisode) {
            this.$router.replace({
              name: 'playtv',
              params: { id: this.tv.id + '', season: this.season.season_number + '', episode: this.nextEpisode.episode_number + '' }
            });
          } else {
            this.$router.push({
              name: 'season',
              params: { id: this.tv.id + '', season: this.season.season_number + '' }
            });
          }
        }
      });
    },
    playlist: function () {
      let elements = [];
      elements.push(
        {
          description: this.episode.overview,
          image: 'https://assets.le-navet.com/static/800' + this.episode.still_path,
          title: this.episode.name,
          file: 'https://tv.le-navet.com/' + this.tv.id + '/' + this.season.season_number + '/' + this.episode.episode_number + '/master.m3u8',
          onXhrOpen: this.onXhrOpen,
          starttime: this.startTime,
          tracks: [
            {
              file: 'https://europe-west1-turnip-820de.cloudfunctions.net/thumbnails?type=tv&id=' + this.tv.id + '&season=' + this.season.season_number + '&episode=' + this.episode.episode_number,
              kind: 'thumbnails'
            }
          ]
        });
      if (this.nextEpisode) {
        elements.push(
          {
            description: this.nextEpisode.overview,
            image: 'https://assets.le-navet.com/static/800' + this.nextEpisode.still_path,
            title: this.nextEpisode.name,
            file: 'https://tv.le-navet.com/' + this.tv.id + '/' + this.season.season_number + '/' + this.nextEpisode.episode_number + '/master.m3u8',
            onXhrOpen: this.onXhrOpen,
            tracks: [
              {
                file: 'https://europe-west1-turnip-820de.cloudfunctions.net/thumbnails?type=tv&id=' + this.tv.id + '&season=' + this.season.season_number + '&episode=' + this.nextEpisode.episode_number,
                kind: 'thumbnails'
              }
            ]
          });
      }

      return elements;
    },
    onXhrOpen: function (xhr, url) {
      var parsedUrl = new URL(url);

      var opts = {
        service: 's3',
        region: 'eu-central-1',
        method: 'GET',
        host: 'tv.le-navet.com',
        path: parsedUrl.pathname
        // signQuery: true
      };

      aws4.sign(opts, {
        accessKeyId: accessKey,
        secretAccessKey: secretKey
      }); // assumes AWS credentials are available in process.env

      for (let header in opts.headers) {
        if (opts.headers.hasOwnProperty(header) && header !== 'Host') {
          xhr.setRequestHeader(header, opts.headers[header]);
        }
      }
    }
  },
  watch: {
    $route () {
      this.initialize();
    }
  },
  created: function () {
    this.initialize();
  }
};
</script>

<style scoped>
  html, body, #player {
    background-color: transparent;
    color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  body {
    background-color: black;
  }

</style>
